import {
	BaseStoreAssets,
	EntityStoreAssets,
	createBaseStoreAssets,
	createEntityAdapterStoreAssets,
	createStoreAssets
} from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';
import { FacetEntity, IndexedPagination, ProductEntity, SortingEntity } from '@vlaio/shared/types';

import { ProductFiltersEntity } from './interfaces';

export type ProductsStoreSliceType = {
	products: EntityStoreAssets<ProductEntity>;
	facets: EntityStoreAssets<FacetEntity>;
	spotlight: EntityStoreAssets<ProductEntity>;
	sortingOptions: EntityStoreAssets<SortingEntity>;
	detail: BaseStoreAssets<ProductEntity>;
	filters: BaseStoreAssets<ProductFiltersEntity>;
	paging: BaseStoreAssets<IndexedPagination>;
	nextPage: BaseStoreAssets<string>;
};

export const { actions, reducers, selectors } = createStoreAssets<ProductsStoreSliceType>(StoreSlice.Products, [
	{
		subSlice: 'products',
		generator: createEntityAdapterStoreAssets<ProductEntity>
	},
	{
		subSlice: 'facets',
		generator: createEntityAdapterStoreAssets<FacetEntity>
	},
	{
		subSlice: 'spotlight',
		generator: createEntityAdapterStoreAssets<ProductEntity>
	},
	{
		subSlice: 'sortingOptions',
		generator: createEntityAdapterStoreAssets<SortingEntity>
	},
	{
		subSlice: 'detail',
		generator: createBaseStoreAssets<ProductEntity>
	},
	{
		subSlice: 'filters',
		generator: createBaseStoreAssets<ProductFiltersEntity>
	},
	{
		subSlice: 'paging',
		generator: createBaseStoreAssets<IndexedPagination>
	},
	{
		subSlice: 'nextPage',
		generator: createBaseStoreAssets<string>
	}
]);
