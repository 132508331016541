import { ProductEntity } from '@vlaio/shared/types';

/**
 * The type of the link that is clicked within a product card.
 */
export interface ProductActionLinkEventEntity {
	type: keyof typeof ProductActionType;
	product: ProductEntity;
}

/**
 * The type of link within a product card.
 */
export enum ProductActionType {
	Partner = 'Partner info link click',
	MoreInfo = 'Product info link click',
	InfoLink = 'Extra info link click',
	Register = 'Product ingeladen'
}
