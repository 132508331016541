import { AbstractLanguageProviderService, AbstractTranslationProviderService } from '@vlaio/shared/core';

import { LanguageProviderService } from './language-provider.service';
import { TranslationProviderService } from './translation-provider.service';

export * from './spotlight.service';

// Iben: This list of services are used to avoid circular dependencies
export const providerServices = [
	{
		provide: AbstractLanguageProviderService,
		useClass: LanguageProviderService
	},
	{
		provide: AbstractTranslationProviderService,
		useClass: TranslationProviderService
	}
];
