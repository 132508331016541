import { createEntityAdapterStoreAssets, createStoreAssets, EntityStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';

import { Notification } from './interfaces';

export type NotificationsStoreSliceType = {
	notifications: EntityStoreAssets<Notification>;
};

export const { reducers, selectors, actions } = createStoreAssets<NotificationsStoreSliceType>(
	StoreSlice.Notifications,
	[
		{
			subSlice: 'notifications',
			generator: createEntityAdapterStoreAssets<Notification>
		}
	]
);
