import { EntityStoreAssets, createEntityAdapterStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';
import { PageEntity, PageNavDataEntity } from '@vlaio/shared/types';

export type PageStoreSliceType = {
	navigation: EntityStoreAssets<PageNavDataEntity>;
	pages: EntityStoreAssets<PageEntity>;
};

export const { actions, reducers, selectors } = createStoreAssets<PageStoreSliceType>(StoreSlice.Pages, [
	{
		subSlice: 'navigation',
		generator: createEntityAdapterStoreAssets<PageNavDataEntity>
	},
	{
		subSlice: 'pages',
		generator: createEntityAdapterStoreAssets<PageEntity>
	}
]);
