<ng-container *displayContent="{ error }">
	<vlaio-facet-filter
		[loading]="loading"
		[facets]="facets"
		[isDesktop]="isDesktop"
		[enableClear]="enableClear"
		(clearClicked)="onClearClicked()"
		(facetClicked)="onFacetClicked($event)"
		(filtersChanged)="onFiltersChanged($event)"
	/>

	<div class="vlaio-facet-filters">
		<ng-content select="[filters]"></ng-content>

		@if (showSelectedFacets) {
			<vlaio-selected-facets
				[searchQuery]="searchQuery"
				[filters]="selectedFacets"
				[facetSubjectNames]="facetSubjectNames"
				(filtersChanged)="onFiltersChanged($event)"
				(searchQueryCleared)="onSearchQueryClearClicked()"
				(clearAll)="onClearClicked()"
			/>
		}
	</div>

	<div class="vlaio-facet-content">
		<ng-content select="[content]"></ng-content>
	</div>
</ng-container>
