<button
	[disabled]="isDisabled"
	[class]="buttonClasses[buttonType] + ' u-outline ' + customButtonClass + ' layout-' + layout"
	[ngClass]="{ 'is-disabled': isDisabled }"
	[title]="title"
	[attr.type]="type"
	[cypressTag]="buttonCypressTag"
	(click)="handleButtonClick()"
>
	<ng-content></ng-content>

	@if (icon) {
		<vlaio-icon [icon]="icon" />
	}
</button>
