import { BaseStoreAssets, createBaseStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';

import { PartnersByLevelEntity } from './interfaces';

export type PartnersStoreSliceType = {
	partners: BaseStoreAssets<PartnersByLevelEntity>;
};

// Iben: Create entity selectors and reducers
export const { actions, selectors, reducers } = createStoreAssets<PartnersStoreSliceType>(StoreSlice.Partners, [
	{
		subSlice: 'partners',
		generator: createBaseStoreAssets<PartnersByLevelEntity>
	}
]);
