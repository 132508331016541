// Production-server environment variables
// Test-server environment variables
import { EnvironmentType } from './interfaces';

export const environment = {
	environment: EnvironmentType.PRODUCTION,
	version: '5.1.0',
	production: true,
	domain: 'https://www.e-loketondernemers.be/',
	cookieDomain: '.e-loketondernemers.be',
	metaDomain: 'e-loketondernemers.be',
	loketType: 'eLoket',

	api: {
		protocol: 'https',
		hostname: 'cms.e-loketondernemers.be/',
		apiPath: 'api',
		fullPath: 'https://cms.e-loketondernemers.be/api'
	},

	assets: {
		fullPath: 'https://www.e-loketondernemers.be/assets/',
		styleExtension: '.min.css'
	},

	acmidm: {
		protocol: 'https',
		hostname: 'cms.e-loketondernemers.be/',
		loginPath: 'vlaio/login',
		logoutPath: 'vlaio/logout',
		switchPath: 'vlaio/switch',
		directedLoginPath: 'vlaio/directed-login',
		keepAliveUrl: 'https://authenticatie.vlaanderen.be/favicon.ico',
		widgetsUrl: 'https://prod.widgets.burgerprofiel.vlaanderen.be/',
		headerWidgetId: '3a9b7837-5d0f-4649-8177-a7e18df69874',
		footerWidgetId: '8b465d2b-4864-4040-9e46-dfe98dc3615d'
	},

	sas: {
		enabled: true,
		url: 'https://authenticatie.vlaanderen.be/stb/generic/sasupdate',
		sourceUuid: '70a1f21d-17a5-431b-8468-be9b2f63695f',
		targetUuid: 'e0455213-1cf6-451c-9c99-cca0f78db1c4'
	},

	bpost: {
		host: 'api.mailops.bpost.cloud'
	},

	ebox: {
		guiUrl: 'https://app.eboxenterprise.be/',
		activationUrl: 'https://www.socialsecurity.be/app009/e-Box/Enterprise/',
		infoUrl: 'https://www.eboxenterprise.be/nl/index.html'
	},

	modules: {
		ebox: 1,
		iom: 1
	},

	gtag: {
		trackingCode: 'G-TE3YKLNYR0'
	},
	matomo: {
		siteId: 1
	},
	languages: ['nl'],
	hasServiceWorker: true,
	whatsNewPageId: 'Wat is nieuw?',
	/**
	 * Enable the identifiable tracking for the user.
	 * This will result in the user no longer being tracked by a hash, but by
	 * an identifiable combination of their name and organization number.
	 * This is a MAJOR GDPR breach on production environments.
	 */
	allowIdentifiableTacking: false,

	logErrors: false
};
