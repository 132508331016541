import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormAccessor, createAccessorProviders } from '@studiohyperdrive/ngx-forms';
import { ObservableBoolean, ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { finalize } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';

import { ZipCodeService } from '../../../data';
import { VlaioZipCodeEntity } from '../../../data/interfaces';
import { I18nKeys } from '../../../i18n';
import { VlaioDropdownSearchComponent } from '../dropdown-search/dropdown-search.component';

@Component({
	selector: 'vlaio-zip-code-input',
	templateUrl: './zip-code.component.html',
	providers: [createAccessorProviders(VlaioZipCodeInputComponent)],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		VlaioDropdownSearchComponent,
		CypressTagDirective,
		ReactiveFormsModule,
		NgTemplateOutlet,
		AsyncPipe,
		TranslateModule
	]
})
export class VlaioZipCodeInputComponent extends FormAccessor<VlaioZipCodeEntity, FormControl<VlaioZipCodeEntity>> {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * A custom template to override the default "municipality (code)" listing
	 */
	@ContentChild('itemTmpl') public itemTemplate: TemplateRef<unknown>;

	/**
	 * The available Zip Codes.
	 */
	public readonly zipCodes$: ObservableArray<VlaioZipCodeEntity> = this.zipCodeService.state.zipCodes$;

	/**
	 * Whether an error occurred while fetching the zip codes.
	 */
	public readonly error$: ObservableBoolean = this.zipCodeService.state.zipCodesError$;

	/**
	 * Whether the zip codes are currently being fetched.
	 */
	public readonly loading$: ObservableBoolean = this.zipCodeService.state.zipCodesLoading$;

	/**
	 * Whether the search has been initiated.
	 */
	public isSearchInitiated: boolean = false;

	/**
	 * The outline input attribute can be used to set the outline class on the input.
	 *
	 * Default value is `true`
	 */
	@Input()
	public outline: boolean = true;
	/**
	 * Label when the search has failed
	 */
	@Input() public errorLabel: string;
	/**
	 * A label for when there are no matches
	 */
	@Input() public emptyLabel: string;
	/**
	 * WCAG title for the input
	 */
	@Input() public title: string;
	/**
	 * A mapper to map the selected value as a human readable string to override the default "municipality (code)"
	 */
	@Input() public selectedMapper: (zipCode: VlaioZipCodeEntity) => string;
	/**
	 * Whether or not we want to show the clear button
	 *
	 * Default value is `true`
	 */
	@Input() public showClearButton: boolean = true;

	constructor(private readonly zipCodeService: ZipCodeService) {
		super();
	}

	public initForm(): FormControl<VlaioZipCodeEntity> {
		return new FormControl(null);
	}

	/**
	 * Search a zip code based on the provided searchQuery
	 *
	 * @param searchQuery - The provided search query
	 */
	public searchZipCode(searchQuery: string): void {
		if (!searchQuery) {
			this.form.reset();
		}

		this.zipCodeService
			.searchZipCodes(searchQuery)
			.pipe(
				finalize(() => {
					this.isSearchInitiated = false;
				})
			)
			.subscribe();
	}

	public searchInitiated(): void {
		this.isSearchInitiated = true;
	}

	/**
	 * A default mapper for the selected value
	 *
	 * @param zipCode - The selected zip code
	 */
	public selectedZipCodeMapper(zipCode: VlaioZipCodeEntity): string {
		if (!zipCode) {
			return '';
		}

		return `${zipCode.municipality} (${zipCode.code})`;
	}
}
