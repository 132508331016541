@if ((restrictToPageOnly$ | async) === false) {
	<vlaio-banner [title]="(page$ | async)?.title" />

	<vlaio-breadcrumb class="c-container c-container--spacing u-margin-top"></vlaio-breadcrumb>

	<div class="c-container c-container--spacing u-margin-top-xl">
		<div>
			<ng-template [ngTemplateOutlet]="pageTmpl"></ng-template>
		</div>
	</div>
} @else {
	<vlaio-page
		[loading]="loading$ | async"
		[showTitle]="false"
		[scrollToAnchor]="anchor$ | async"
		[page]="page$ | async"
	></vlaio-page>
}

<ng-template #pageTmpl>
	<vlaio-page
		[loading]="loading$ | async"
		[showTitle]="false"
		[scrollToAnchor]="anchor$ | async"
		[page]="page$ | async"
	></vlaio-page>
</ng-template>
