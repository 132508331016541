@if (!customText?.description?.length) {
	@if (type === 'no-results') {
		<h4>{{ customText?.title || i18nKeys.NoResults.Title | translate }}</h4>
		<p>{{ customText?.subtitle || i18nKeys.NoResults.Subtitle | translate }}</p>
		<div class="icon-list-wrapper">
			<vlaio-icon icon="fa-file-circle-question" />
			<ul>
				<li>{{ i18nKeys.NoResults.List.Item1 | translate }}</li>
				<li>{{ i18nKeys.NoResults.List.Item2 | translate }}</li>
				<li>{{ i18nKeys.NoResults.List.Item3 | translate }}</li>
				<!-- Wouter: Since this contains a link, this needs to be set as innerHTML -->
				@if (searchWithoutFiltersClicked | hasObservers) {
					<li
						cypressTag="General.SearchWithoutFilters"
						[innerHTML]="i18nKeys.NoResults.List.Item4 | translate"
						(click)="emitSearchWithoutFilters($event)"
					></li>
				}
			</ul>
		</div>
	} @else {
		<h4>{{ customText?.title || i18nKeys.Error.Title | translate }}</h4>
		<p>{{ customText?.subtitle || i18nKeys.Error.Subtitle | translate }}</p>
		<div class="icon-list-wrapper">
			<vlaio-icon icon="fa-file-circle-xmark" />
			<ul>
				<li>{{ i18nKeys.Error.List.Item1 | translate }}</li>
				<li>{{ i18nKeys.Error.List.Item2 | translate }}</li>
				<li>{{ i18nKeys.Error.List.Item3 | translate }}</li>
			</ul>
		</div>
	}
} @else {
	<h4>{{ customText.title }}</h4>
	<p>{{ customText.subtitle }}</p>
	<div class="icon-list-wrapper">
		<vlaio-icon icon="fa-file-circle-question" />
		<ul>
			@for (item of customText.description; track item) {
				<li>{{ item }}</li>
			}
		</ul>
	</div>
}
