import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { dispatchDataToStore, StoreService } from '@studiohyperdrive/ngx-store';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';

import { actions, FormsStoreSliceType, selectors } from '../../forms-data.store';
import { VlaioZipCodeEntity } from '../../interfaces';

import { ZipCodeApiService } from './zip-code.api.service';

@Injectable()
export class ZipCodeService extends StoreService<FormsStoreSliceType> {
	constructor(
		private readonly apiService: ZipCodeApiService,
		public readonly store: Store
	) {
		super(store, selectors);
	}

	/**
	 * Fetches and returns a list of zip codes
	 *
	 * @param searchQuery - The search query matching a zip code or municipality
	 */
	public searchZipCodes(searchQuery: string): ObservableArray<VlaioZipCodeEntity> {
		return dispatchDataToStore(actions.zipCodes, this.apiService.getZipCodes(searchQuery), this.store);
	}
}
