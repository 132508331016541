import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgxDisplayContentComponent } from '@studiohyperdrive/ngx-layout';

import { VlaioEmptySearchResultComponent } from '../../empty-search-result/empty-search-result.component';

@Component({
	selector: 'vlaio-error',
	standalone: true,
	template: `<vlaio-empty-search-result type="error" [customText]="{ subtitle: data }" />`,
	imports: [VlaioEmptySearchResultComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlaioErrorComponent extends NgxDisplayContentComponent {}
