<ng-container *displayContent="{ loading }">
	@if (products?.length > 0) {
		<ul class="u-reset-list">
			@for (product of products; track product) {
				<li>
					<vlaio-product-detail
						#product
						[product]="product"
						[recommendationRating]="recommendations[product.id]"
						(recommendationRated)="recommendationRated.emit({ productId: product.id, rating: $event })"
						(ctaClicked)="ctaClicked.emit($event)"
					>
					</vlaio-product-detail>
				</li>
			}
		</ul>

		@if (hasNextPage) {
			<ng-container
				*displayContent="
					{ loading: moreLoading };
					configuration: { loading: { data: i18nKeys.Products.Loading.Products | translate } }
				"
			/>
			<vlaio-button
				class="more-button"
				[title]="i18nKeys.Products.Actions.LoadMore | translate"
				(handleClick)="loadMoreClicked.emit()"
			>
				{{ i18nKeys.Products.Actions.LoadMore | translate }}
			</vlaio-button>
		}
	} @else {
		@if (!isEmptyAfterFilteredSearch) {
			<vlaio-empty-search-result />
		} @else {
			<vlaio-empty-search-result (searchWithoutFiltersClicked)="searchWithoutFiltersClicked.emit()" />
		}
	}
</ng-container>
