import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { merge } from '@studiohyperdrive/utils';
import { Observable, combineLatest, map, of } from 'rxjs';

import { PermitsEntity, PermitsSummaryEntity, RenewablePermitsEntity } from '../interfaces';
import { actions, PermitsStoreSliceType, selectors } from '../permits.store';

import { PermitsApiService } from './permits.api.service';

@Injectable()
export class PermitsService extends StoreService<PermitsStoreSliceType> {
	constructor(
		public readonly store: Store,
		private readonly apiService: PermitsApiService
	) {
		super(store, selectors);
	}

	/**
	 * Fetches the permits from the API.
	 *
	 * @returns An observable of the fetched permits array.
	 */
	public getPermits(markRenewablePermits: boolean = false): ObservableArray<PermitsEntity> {
		return dispatchDataToStore(
			actions.permits,
			combineLatest([
				this.apiService.getPermits(),
				markRenewablePermits ? this.apiService.getRenewablePermits() : of([])
			]).pipe(
				map(([permits, renewablePermits]) => {
					const renewable = new Set(renewablePermits.map(({ reference }) => reference));

					// Iben: Add the isRenewable flag when needed
					return permits.map((permit) => {
						return merge(permit, ['isRenewable', renewable.has(permit.reference) || undefined]);
					});
				})
			),
			this.store
		);
	}

	/**
	 * Fetches the summary of the permits from the API.
	 *
	 * @returns An observable of the fetched summary.
	 */
	public getPermitsSummary(): Observable<PermitsSummaryEntity> {
		return dispatchDataToStore(actions.summary, this.apiService.getPermitsSummary(), this.store);
	}

	/**
	 * Fetches the permits that are eligible for a renewal.
	 *
	 * @returns An array of the permits that require renewal as an observable.
	 */
	public getRenewablePermits(): ObservableArray<RenewablePermitsEntity> {
		return dispatchDataToStore(actions.renewablePermits, this.apiService.getRenewablePermits(), this.store);
	}
}
