import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params, QueryParamsHandling, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';

import { VlaioLinkAbstractComponent } from '../shared';

/**
 * A component that renders an internal link. The navigation is done using the Angular Router.
 */
@Component({
	selector: 'vlaio-internal-link',
	template: ` <a
		class="u-outline u-link__underline"
		routerLinkActive="is-active"
		[id]="linkId"
		[ngClass]="{ 'font-bold primary-color': linkStyle === 'styled' }"
		[title]="title ? (i18nKeys.Links.Internal | translate: { title }) : ''"
		[routerLink]="link"
		[queryParams]="linkQueryParams"
		[queryParamsHandling]="linkQueryParamsHandling"
		[cypressTag]="linkCypressTag"
	>
		<ng-content></ng-content>
	</a>`,
	styleUrls: ['../shared/shared-link.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterLink, TranslateModule, NgClass, RouterLinkActive, CypressTagDirective]
})
export class VlaioInternalLinkComponent extends VlaioLinkAbstractComponent {
	/**
	 * The fragments (parameters) to be added to the URL query.
	 */
	@Input() public linkQueryParams: Params;

	/**
	 * How to handle query parameters in the URL.
	 *
	 * Default value is `merge`.
	 */
	@Input() public linkQueryParamsHandling: QueryParamsHandling = 'merge';
}
