<div>
	<div class="c-modal__header">
		<h3 class="c-modal__title" id="action-modal-title">{{ data.title }}</h3>
	</div>

	<div class="c-modal__body">
		<p id="action-modal-description">
			{{ data.text }}
		</p>
	</div>

	<div class="c-modal__footer">
		<vlaio-button class="c-modal__action" [title]="data.confirmLabel" (click)="action.emit('Proceed')">
			{{ data.confirmLabel }}
		</vlaio-button>

		@if (data.cancelLabel) {
			<vlaio-button
				class="c-modal__action"
				buttonType="RegularOutline"
				[title]="data.cancelLabel"
				(click)="action.emit('Cancel')"
			>
				{{ data.cancelLabel }}
			</vlaio-button>
		}
	</div>
</div>
