<h1>
	@for (title of titles; track title) {
		<span>
			<ng-template
				[ngTemplateOutlet]="title.canNavigate ? linkTmpl : defaultTmpl"
				[ngTemplateOutletContext]="{ $implicit: title }"
			></ng-template>
		</span>
	}

	<ng-content select="[help]"></ng-content>
</h1>

<ng-template #defaultTmpl let-item>
	<span>{{ item.label }}</span>
</ng-template>

<ng-template #linkTmpl let-item>
	<vlaio-internal-link
		linkStyle="plain"
		[link]="item.route"
		[linkQueryParams]="item.queryParams || {}"
		[title]="item.title"
	>
		{{ item.label }}
	</vlaio-internal-link>
</ng-template>
