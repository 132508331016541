import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FocusClickDirective } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';

import { VlaioLinkAbstractComponent } from '../shared';

/**
 * When clicked, this component emits an event. It does not navigate on its own, but is styled
 * in the same way the `VlaioInternalLinkComponent` is styled.
 */
@Component({
	selector: 'vlaio-event-link',
	template: `<a
		class="u-link__underline u-outline"
		[title]="title"
		[id]="linkId"
		[ngClass]="{ 'font-bold primary-color': linkStyle === 'styled' }"
		[cypressTag]="linkCypressTag"
		(focusClick)="linkClicked.emit()"
	>
		<ng-content></ng-content>
	</a>`,
	styleUrls: ['../shared/shared-link.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FocusClickDirective, NgClass, CypressTagDirective]
})
export class VlaioEventLinkComponent extends VlaioLinkAbstractComponent {}
