import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';

import { NaceBelEntity, NacebelSearchEntity } from '../interfaces';
import { actions, NacebelCodeStoreSliceType, selectors } from '../nacebel.store';

import { NacebelApiService } from './nacebel.api.service';

@Injectable()
export class NacebelService extends StoreService<NacebelCodeStoreSliceType> {
	constructor(
		public readonly store: Store,
		private readonly apiService: NacebelApiService
	) {
		super(store, selectors);
	}

	public getNacebelCodes(): ObservableArray<NaceBelEntity> {
		return dispatchDataToStore(actions.nacebelCodes, this.apiService.getNacebelCodes(), this.store, 'set');
	}

	public searchNacebelCodes(searchValue: string): ObservableArray<NacebelSearchEntity> {
		return dispatchDataToStore(
			actions.nacebelSearchResults,
			this.apiService.searchNacebelCodes(searchValue),
			this.store,
			'set'
		);
	}
}
