<ng-container *displayContent="{ loading, error }">
	@if (title || content) {
		<article>
			@if (showTitle) {
				<h1>
					{{ title }}
				</h1>
			}
			<div class="u-wysiwyg" #pageContent [innerHtml]="content"></div>
		</article>
	}
</ng-container>
