import { Injectable } from '@angular/core';
import { EMPTY, fromEvent, Observable, ReplaySubject } from 'rxjs';

import { BrowserService } from '@vlaio/shared/core';

// TODO: Iben: Move this to the package once it is considered stable enough
/**
 * A service that wraps the BroadCastChannel API and provides an Observable based implementation to the channel messages.
 *
 * For more information:
 * https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
 */
@Injectable({
	providedIn: 'root'
})
export class NgxBroadcastChannelService {
	/**
	 * A record holding all the broadcast channels
	 */
	private broadcastChannel: Record<string, BroadcastChannel> = {};

	/**
	 * A record holding all the broadcast channels corresponding subjects
	 */
	private broadcastSubjectRecord: Record<string, ReplaySubject<unknown>> = {};

	constructor(private readonly windowService: BrowserService) {}

	/**
	 * initChannel
	 *
	 * The initChannel method initializes a new BroadcastChannel instance.
	 *
	 * @param args{ConstructorParameters<typeof BroadcastChannel>} - The arguments to pass to the BroadcastChannel constructor.
	 */
	public initChannel<MessageType = any>(
		bufferSize: number,
		windowTime: number,
		...args: ConstructorParameters<typeof BroadcastChannel>
	): void {
		// Iben: Only run when in browser
		this.windowService.runInBrowser(() => {
			const [channelName] = args;

			if (!channelName) {
				console.error(
					'NgxUtils: There was an attempt to initialize a BroadcastChannel without providing a name.'
				);

				return;
			}

			if (this.broadcastChannel[channelName]) {
				return;
			}

			// Iben: Initialize the channel and create a subject
			const channel = new BroadcastChannel(...args);
			const subject = new ReplaySubject<MessageEvent<MessageType>>(bufferSize, windowTime);

			// Iben: Send the message to the subject
			channel.onmessage = (event) => {
				subject.next(event);
			};

			// Iben: Update the subjects and channel records
			this.broadcastChannel[channelName] = channel;
			this.broadcastSubjectRecord[channelName] = subject;
		});
	}

	/**
	 * closeChannel
	 *
	 * The closeChannel method closes a selected BroadcastChannel instance.
	 *
	 * @param channelName{string} - The name of the Broadcast Channel.
	 */
	public closeChannel(channelName: string): void {
		if (!channelName || !this.broadcastChannel[channelName]) {
			return;
		}

		this.broadcastChannel[channelName].close();
		delete this.broadcastChannel[channelName];
	}

	/**
	 * postMessage
	 *
	 * The postMessage method sends a message to a selected BroadcastChannel instance.
	 *
	 * @param channelName{string} - The name of the Broadcast Channel.
	 * @param message{any} - The payload to send through the channel.
	 */
	public postMessage<MessageType = any>(channelName: string, message: MessageType): void {
		if (!channelName || !this.broadcastChannel[channelName]) {
			console.error(
				'NgxUtils: There was an attempt to post a message to a channel without providing a name or the selected channel does not exist. The included message was:',
				message
			);

			return;
		}

		this.broadcastChannel[channelName].postMessage(message);
	}

	/**
	 * selectChannelMessages
	 *
	 * The selectChannelMessages method subscribes to the `message` (bc.onmessage) event of a selected BroadcastChannel instance.
	 *
	 * @param channelName{string} - The name of the Broadcast Channel.
	 * @returns Observable<MessageEvent> - The message event of the channel wrapped in an observable.
	 */
	public selectChannelMessages<MessageType = any>(channelName: string): Observable<MessageEvent<MessageType>> {
		if (!channelName || !this.broadcastChannel[channelName]) {
			console.error(
				"NgxUtils: There was an attempt to select a BroadcastChannel's messages without providing a name or the selected channel does not exist."
			);

			return EMPTY;
		}

		return this.broadcastSubjectRecord[channelName].asObservable() as Observable<MessageEvent<MessageType>>;
	}

	/**
	 * selectChannelMessageErrors
	 *
	 * The selectChannelMessageErrors method subscribes to the `messageerror` (bc.onmessageerror) event of a selected BroadcastChannel instance.
	 *
	 * @param channelName{string} - The name of the Broadcast Channel.
	 * @returns Observable<MessageEvent> - The messageerror event of the channel wrapped in an observable.
	 */
	public selectChannelMessageErrors<MessageType = any>(channelName: string): Observable<MessageEvent<MessageType>> {
		if (!channelName || !this.broadcastChannel[channelName]) {
			console.error(
				"NgxUtils: There was an attempt to select a BroadcastChannel's message errors without providing a name or the selected channel does not exist."
			);

			return EMPTY;
		}

		return fromEvent<MessageEvent<MessageType>>(this.broadcastChannel[channelName], 'messageerror');
	}
}
