import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxDisplayContentComponent } from '@studiohyperdrive/ngx-layout';

import { CypressTagDirective } from '@vlaio/cypress/core';

@Component({
	selector: 'vlaio-loader',
	standalone: true,
	templateUrl: './loader.component.html',
	styleUrl: './loader.component.scss',
	imports: [CypressTagDirective],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlaioLoaderComponent extends NgxDisplayContentComponent {
	/**
	 * The label to show above to the loading animation.
	 */
	@Input() public label: string = '';
}
