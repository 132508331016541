import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { NgxDisplayContentDirective } from '@studiohyperdrive/ngx-layout';
import { pluck, ObservableString, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { MetaService } from '@vlaio/shared/core';
import { PageComponent, PageService } from '@vlaio/shared/page';
import { AbstractPageComponent, PageEntity, Pages } from '@vlaio/shared/types';
import { VlaioBreadcrumbComponent, BreadcrumbService } from '@vlaio/shared/ui/breadcrumb';
import { VlaioBannerComponent, VlaioContentComponent } from '@vlaio/shared/ui/common';

@Component({
	selector: 'vlaio-footer-page',
	templateUrl: './footer-page.component.html',
	standalone: true,
	imports: [
		VlaioBannerComponent,
		VlaioBreadcrumbComponent,
		VlaioContentComponent,
		PageComponent,
		AsyncPipe,
		NgxDisplayContentDirective
	]
})
export class FooterPageComponent extends AbstractPageComponent implements OnInit {
	/**
	 * Whether the page content is being fetched;
	 */
	public readonly loading$: ObservableBoolean = this.pageService.state.pagesLoading$;

	/**
	 * Set the breadcrumbs of the page.
	 */
	public readonly breadcrumbs$: Observable<PageEntity> = this.page$.pipe(
		filter<PageEntity>(Boolean),
		tap((page) => {
			this.breadcrumbsService.setBreadcrumbs([
				{
					routePath: [],
					title: page.title,
					allowNav: false
				}
			]);
		}),
		takeUntilDestroyed()
	);

	constructor(
		public readonly route: ActivatedRoute,
		private readonly pageService: PageService,
		private readonly breadcrumbsService: BreadcrumbService,
		private readonly metaService: MetaService
	) {
		super(route);
	}

	// Lifecycle methods
	// ==============================
	public ngOnInit() {
		// Iben: Set breadCrumb path
		this.breadcrumbs$.subscribe();

		this.page$
			.pipe(
				tap((page) => {
					this.metaService.updateMetaData({ title: page.title, description: '' });
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	// Abstract methods
	// ==============================
	public getPageId(): ObservableString {
		return this.route.queryParams.pipe(filter(Boolean), pluck('page'));
	}

	public getPage(page: string): Observable<PageEntity> {
		return this.pageService.getPage(Pages.Footer, page);
	}
}
