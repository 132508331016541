import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { FacetsService } from '@vlaio/shared/facets';
import { FacetEntity, FacetFilter, ProductEntity, ProductsFilter, SortingEntity } from '@vlaio/shared/types';

import { ProductFiltersEntity } from '../interfaces';
import { actions, ProductsStoreSliceType, selectors } from '../store';

import { ProductApiService } from './product-api.service';

@Injectable()
export class ProductService extends StoreService<ProductsStoreSliceType> {
	/**
	 * Array of facets and their subjects
	 */
	public readonly facets$: ObservableArray<FacetEntity> = this.facetsService.facets$;

	/**
	 * Loading state of the facets
	 */
	public readonly facetsLoading$: ObservableBoolean = this.facetsService.facetsLoading$;

	/**
	 * Error state of the facets
	 */
	public readonly facetsError$: ObservableBoolean = this.facetsService.facetsError$;

	constructor(
		private readonly apiService: ProductApiService,
		private readonly facetsService: FacetsService,
		public readonly store: Store
	) {
		super(store, selectors);
	}

	/**
	 * Fetch the offered products from the api and dispatch them to the store
	 *
	 * @param filters - The selected facets
	 * @param dispatchType - Whether the fetched products need to be replace those in the store or be added to the products in the store
	 */
	public getOfferedProducts(
		filter: ProductsFilter = {},
		dispatchType: 'set' | 'add' = 'set'
	): ObservableArray<ProductEntity> {
		return dispatchDataToStore<ProductEntity[]>(
			actions.products,
			this.apiService.getOfferedProducts(filter).pipe(
				map(({ products, nextPage, pagination }) => {
					// Iben: Dispatch the next page to the store
					this.store.dispatch(actions.nextPage.set({ payload: nextPage }));
					this.store.dispatch(actions.paging.set({ payload: pagination }));
					return products;
				})
			),
			this.store,
			dispatchType
		);
	}

	/**
	 * Set the current filters
	 *
	 * @param payload - The selected filters
	 */
	public setFilters(payload: ProductFiltersEntity) {
		this.store.dispatch(actions.filters.set({ payload }));
	}

	/**
	 * Fetches the next page of the currently filtered offered products
	 */
	public getNextPageOfOfferedProducts() {
		return combineLatest([this.state.nextPage$, this.state.filters$]).pipe(
			take(1),
			switchMap(([index, { filters, searchQuery, sorting }]) =>
				this.getOfferedProducts({ index, filters, searchQuery, sorting: sorting.id }, 'add')
			)
		);
	}

	/**
	 * Fetch the facets from the api and dispatch them to the store
	 *
	 * @param filters - The selected facets
	 */
	public getFacets(filters: FacetFilter = {}, searchQuery: string = undefined): ObservableArray<FacetEntity> {
		return this.facetsService.getFacets(filters, searchQuery);
	}

	/**
	 * Fetches the spotlight products from the api and dispatches them to the store
	 */
	public getSpotlightProducts(): ObservableArray<ProductEntity> {
		return dispatchDataToStore(actions.spotlight, this.apiService.getSpotlightProducts(), this.store);
	}

	/**
	 * Fetches the detail product from the api and dispatches them to the store
	 */
	public getDetailProduct(id: string, withCredentials: boolean = false): Observable<ProductEntity> {
		return dispatchDataToStore(actions.detail, this.apiService.getProductDetail(id, withCredentials), this.store);
	}

	/**
	 * Clear the current product detail from store
	 */
	public clearDetailProduct() {
		return dispatchDataToStore(actions.detail, of(null), this.store);
	}

	/**
	 * Clear the current offered products from store
	 */
	public clearOfferedProducts(): Observable<ProductEntity[]> {
		return dispatchDataToStore(actions.products, of([]), this.store);
	}

	/**
	 * Fetch sorting options from api and dispatches them to the store
	 */
	public getSortingOptions(): ObservableArray<SortingEntity> {
		return dispatchDataToStore(actions.sortingOptions, this.apiService.getSortingOptions(), this.store);
	}
}
