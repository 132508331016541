import { Injectable } from '@angular/core';
import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { LeafletMarkerEntity, LeafletService, TileLayerConfig } from '@vlaio/shared/types';

import { CompanyEntity } from '../../../../../../shared/company/src/lib/data/interfaces';

import { CompanyService } from './company.service';

@Injectable()
export class CompanyLeafletService extends LeafletService {
	constructor(private readonly companyService: CompanyService) {
		super();
	}

	public get baseLayers$(): ObservableArray<TileLayerConfig> {
		return of([
			// Filip: This is  base layer is for Brussels.
			// Since the WMTS of vlaanderen doesn't have this.
			// So we can't leave this one out.
			{
				url: 'https://geoservices-urbis.irisnet.be/geowebcache/service/wmts',
				options: {
					layer: 'urbisNLGray',
					style: '',
					tilematrixSet: 'EPSG:900913',
					format: 'image/png',
					attribution: '© CIBG'
				}
			},
			// Create base tilelayer
			{
				url: 'https://geo.api.vlaanderen.be/GRB/wmts',
				options: {
					attribution: '© Agentschap Informatie Vlaanderen',
					layer: 'grb_bsk_grijs',
					style: '',
					tilematrixSet: 'GoogleMapsVL',
					format: 'image/png',
					prefixTileMatrix: false
				}
			}
		]);
	}
	public get orthoLayers$(): ObservableArray<TileLayerConfig> {
		// Abdurrahman: New geo services URL. See https://feedback.informatievlaanderen.be/t/nieuwe-url-voor-alle-geografische-webdiensten/363
		return of([
			{
				url: 'https://geo.api.vlaanderen.be/OMWRGBMRVL/wmts',
				options: {
					attribution: '© Agentschap Informatie Vlaanderen',
					layer: 'omwrgbmrvl',
					style: '',
					tilematrixSet: 'GoogleMapsVL',
					format: 'image/png',
					prefixTileMatrix: false
				}
			}
		]);
	}

	public get hasWalloonsLocation$(): ObservableBoolean {
		return this.getCompany().pipe(
			map((company) => {
				// Iben: If one or more branches is in Walloons, we return true
				return (company.branches || []).some((branch) => branch.address.isWalloons);
			})
		);
	}

	public get leafletMarkers$(): ObservableArray<LeafletMarkerEntity> {
		return this.getCompany().pipe(
			map((company) => {
				const branches = [];

				// Iben: Add markers to the markerData based on the provided branches
				(company?.branches || []).forEach((branch) => {
					const popupData = [];

					if (branch.names.commercial) {
						popupData.push(`<strong>${branch.names.commercial}</strong>`);
					}
					if (branch.address.street || branch.address.number || branch.address.number) {
						popupData.push(
							[
								branch.address.street,
								branch.address.number,
								branch.address.box ? 'bus ' + branch.address.box : null
							]
								.filter(Boolean)
								.join(' ')
						);
					}
					if (branch.address.zip || branch.address.municipality) {
						popupData.push([branch.address.zip, branch.address.municipality].filter(Boolean).join(' '));
					}
					if (branch.address.country) {
						popupData.push(branch.address.country);
					}

					// Iben: If no coordinates are provided, we do not add the marker to the branches
					if (branch.address.coordinates.lat && branch.address.coordinates.long) {
						const marker: LeafletMarkerEntity = {
							id: parseInt(branch.number, 1),
							lat: branch.address.coordinates.lat,
							lng: branch.address.coordinates.long,
							popup: popupData.join('<br/>')
						};

						branches.push(marker);
					}
				});

				return branches;
			})
		);
	}

	private getCompany(): Observable<CompanyEntity> {
		return this.companyService.state.detailId$.pipe(
			switchMap((id) => {
				// Iben: If we don't have a current id, we are in the user company flow
				if (!id) {
					return this.companyService.state.userCompany$;
				}

				// Iben: If we have an id, we fetch the company from the searched companies
				return this.companyService.state.companies$.pipe(
					map((companies) => companies.find((item) => item.number === id))
				);
			}),
			filter<CompanyEntity>(Boolean)
		);
	}
}
