import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { environment, EnvironmentType } from '~environment';

/**
 * This directive is used to render elements based on the current environment.
 */
@Directive({
	selector: '[acceptedEnvironments]',
	standalone: true
})
export class EnvironmentDirective {
	constructor(
		private readonly viewContainer: ViewContainerRef,
		private templateRef: TemplateRef<unknown>
	) {}

	/**
	 * When provided with a list of environments, the directive will only render the element if the current
	 * environment is in the list. This can be used to render certain elements on specific environments
	 * only. This should be used sparingly as it can lead to confusion.
	 */
	@Input({ required: true }) public set acceptedEnvironments(allowedEnvironments: `${EnvironmentType}`[]) {
		// Iben: We check if the current environment is allowed
		if (!allowedEnvironments.includes(environment.environment)) {
			// Wouter: We remove the element from the DOM
			this.viewContainer.clear();
		} else {
			// Wouter: We render the element
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}
}
