import { ProductEntity } from './product.entity';

export interface CallToActionResult {
	naam: string;
	doelVanDeActie: CallToActionResultEnum;
	annotaties: string[];
	actieLink: string;
}

export enum CallToActionResultEnum {
	AanvraagStarten = 'AanvraagStarten',
	ToegangVragen = 'ToegangVragen',
	MeerInfoBekijken = 'MeerInfoBekijken',
	Aanmelden = 'Aanmelden',
	DuoZelfRealisatie = 'DuoZelfRealisatie',
	DuoBezwaar = 'DuoBezwaar',
	ToegangVragenEloket = 'ToegangVragenEloket'
}

export enum CallToActionEnum {
	StartCase = 'START_CASE',
	RequestAccess = 'REQUEST_ACCESS',
	MoreInfo = 'MORE_INFO',
	Login = 'LOGIN',
	DuoSelfRealisation = 'DUO_SELF_REALISATION',
	DuoObjection = 'DUO_OBJECTION',
	RequestAccessEloket = 'REQUEST_ACCESS_ELOKET'
}

export enum CallToActionGTATypeEnum {
	MORE_INFO = 'btn_click_meer_informatie',
	REQUEST_ACCESS = 'btn_click_toegang_vragen',
	START_CASE = 'btn_click_aanvraag_starten'
}

export enum CallToActionGTALabelEnum {
	Offers = 'Zoek in het aanbod',
	Spotlight = 'Dashboard'
}

export interface CallToActionEntity {
	name: string;
	action: CallToActionEnum;
	link: string;
	annotations: string[];
}

export interface CallToActionClickedEvent {
	action: CallToActionEntity;
	product: ProductEntity;
}
