import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NgxDisplayContentDirective } from '@studiohyperdrive/ngx-layout';

import { BrowserService } from '@vlaio/shared/core';
import { FacetEntity, FacetFilter } from '@vlaio/shared/types';
import { VlaioEmptySearchResultComponent } from '@vlaio/shared/ui/common';

import { FacetFilterComponent } from '../facet-filter/facet-filter.component';
import { SelectedFacetsComponent } from '../selected-facets/selected-facets.component';

@Component({
	selector: 'vlaio-facet-container',
	templateUrl: './facet-container.component.html',
	styleUrls: ['./facet-container.component.scss'],
	standalone: true,
	imports: [
		FacetFilterComponent,
		SelectedFacetsComponent,
		VlaioEmptySearchResultComponent,
		NgxDisplayContentDirective
	]
})
export class FacetContainerComponent {
	@HostBinding('class') private readonly rootClass = 'u-margin-top';

	/**
	 * Whether or not we're currently in a desktop view, by default this value is false
	 */
	@Input() public isDesktop = false;

	/**
	 * Whether or not the clear button needs to be shown, by default this value is false
	 */
	@Input() public enableClear: boolean = false;

	/**
	 * A list of facets we want to show in the filter list
	 */
	@Input({ required: true }) public facets: FacetEntity[] = [];

	/**
	 * The loading state of the facets
	 */
	@Input() public loading: boolean;

	/**
	 * An optional search query
	 */
	@Input() public searchQuery: string;

	/**
	 * An optional set of selected facets
	 */
	@Input() public selectedFacets: FacetFilter;

	/**
	 * A record of facet names with as key the id of the facet
	 */
	@Input() public facetSubjectNames: Record<string, string> = {};

	/**
	 * Whether or not we want to show the currently selectedFacets
	 */
	@Input() public showSelectedFacets: boolean = false;

	/**
	 * If set to `true`, the scroll position will be reset to 0 when the
	 * facets are updated.
	 *
	 * The default value is `true`.
	 */
	@Input() public resetScroll: boolean = true;

	/**
	 * Whether a page-blocking error has occurred.
	 */
	@Input() @HostBinding('class.has-error') public error: boolean = false;

	/**
	 * An output for when the clear all button is clicked
	 */
	@Output() private readonly clearClicked = new EventEmitter<void>();

	/**
	 * An output for when any of the filters have changed
	 */
	@Output() private readonly filtersChanged = new EventEmitter<FacetFilter>();

	/**
	 * An output for when a facet is clicked
	 */
	@Output() private readonly facetClicked = new EventEmitter<string>();

	/**
	 * An output for when the search query is cleared
	 */
	@Output() private readonly searchQueryClearClicked: EventEmitter<void> = new EventEmitter<void>();

	constructor(private readonly browserService: BrowserService) {}

	/**
	 * Handle the clear button click event.
	 */
	public onClearClicked(): void {
		this.handleScroll(() => this.clearClicked.emit());
	}

	/**
	 * Handle the event when the filters have changed.
	 *
	 * @param filters The new filters
	 */
	public onFiltersChanged(filters: FacetFilter): void {
		this.handleScroll(() => this.filtersChanged.emit(filters));
	}

	/**
	 * Handle the event when a facet is clicked.
	 *
	 * @param facetId The id of the facet that was clicked
	 */
	public onFacetClicked(facetId: string): void {
		this.handleScroll(() => this.facetClicked.emit(facetId));
	}

	/**
	 * Handle the event when the search query is cleared.
	 */
	public onSearchQueryClearClicked(): void {
		this.handleScroll(() => this.searchQueryClearClicked.emit());
	}

	/**
	 * If needed, the scroll position will be reset to the top.
	 *
	 * @param callback The method to call after the scroll has been handled.
	 */
	private handleScroll(callback: () => any): void {
		if (this.resetScroll) {
			// reset scroll position to top
			// this.browserService.scrollToTop();
		}

		callback();
	}
}
