import { Directive, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, filter, pairwise, startWith, take, takeUntil, tap } from 'rxjs';

import { OnDestroyComponent } from '@vlaio/shared/types';

import { BrowserService } from '../services';

type StringifiedRouteFilterType<FilterType> = {
	[key in keyof FilterType]: string;
};

@Directive()
export abstract class RouteFiltersComponent<RouteFiltersType> extends OnDestroyComponent implements OnInit, OnDestroy {
	/**
	 * The subject that can be triggered to reset the scroll position to the top.
	 */
	private readonly resetScrollPositionSubject: Subject<void> = new Subject<void>();

	/**
	 * The control in which we will save the filter data
	 */
	protected readonly control: FormControl<RouteFiltersType> = new FormControl<RouteFiltersType>(null);

	/**
	 * The query params we wish to control
	 */
	protected queryParams$: Observable<StringifiedRouteFilterType<RouteFiltersType>> = this.route
		.queryParams as Observable<StringifiedRouteFilterType<RouteFiltersType>>;

	constructor(
		protected readonly route: ActivatedRoute,
		protected readonly router: Router,
		protected readonly browserService: BrowserService
	) {
		super();
	}

	public ngOnInit(): void {
		// Iben: Listen to the form changes
		this.control.valueChanges
			.pipe(
				startWith({} as RouteFiltersType),
				// Wouter: Take the previous and current filters
				pairwise(),
				tap(([prevFilters, currFilters]) => {
					// Iben: Update the route params
					this.setFiltersInRoute(currFilters);

					// Iben: Handle the route filters
					this.handleFilters(currFilters);

					// Wouter: Handle the tracking
					if (this.handleTracking) {
						// Wouter: Pass both the previous and current filters to the tracking method
						// This way, we can compare the filters and see what has changed
						this.handleTracking(prevFilters, currFilters);
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Iben: Listen to the initial query param update so we can set the data in the control if we navigate to a link with the params
		this.queryParams$
			.pipe(
				take(1),
				filter(Boolean),
				tap((filters) => {
					// Iben: Convert the route filter properties to the actual data
					let value: RouteFiltersType = Object.keys(filters || {}).reduce((previous, current) => {
						return {
							...previous,
							[current]: filters[current] ? JSON.parse(filters[current]) : undefined
						};
					}, {}) as RouteFiltersType;

					// Iben: In case the unscrambleParams method is provided, we unscramble the data
					if (this.unscrambleParams) {
						value = this.unscrambleParams(value);
					}

					// Iben: Set the current control value
					this.control.setValue(value);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Wouter: Update the scroll position when the filters change
		this.resetScrollPositionSubject
			.pipe(
				tap(() => this.browserService.scrollToTop()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();

		this.clearFilters();
	}

	/**
	 * Clear all the filters.
	 * Essentially resets the control to its initial state.
	 */
	protected clearFilters(): void {
		this.control.reset();
	}

	/**
	 * Reset the scroll position to the top of the page.
	 */
	protected resetScrollPosition(): void {
		this.resetScrollPositionSubject.next();
	}

	/**
	 * Sets the provided filters in the route, so the filtered view can be shared by url
	 *
	 * @param  filters - The provided filters
	 */
	private setFiltersInRoute(filters: RouteFiltersType): void {
		// Iben: If no filters are provided, we simply unset the current params
		if (Object.keys(filters || {}).length === 0) {
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams: {}
			});

			return;
		}

		// Iben: In case a scrambleParams function was provided, we scramble the params first
		const parsedFilters = this.scrambleParams ? this.scrambleParams(filters) : filters;

		// Iben: Stringify all properties of the filter
		const queryParams = Object.keys(parsedFilters || {}).reduce((previous, current) => {
			return {
				...previous,
				[current]: JSON.stringify(parsedFilters[current])
			};
		}, {});

		// Iben: Add the queryParams to the route
		this.router.navigate([], {
			relativeTo: this.route,
			queryParamsHandling: 'merge',
			queryParams
		});
	}

	/**
	 * A method that will handle what happens when the filters have been update. Do NOT subscribe to an Observable in this method
	 *
	 * @param filters - The filters provided by the control
	 */
	protected abstract handleFilters(filters: RouteFiltersType): void;

	/**
	 * A method to scramble the parameters if needed, so no data gets added into the route that shouldn't be shared
	 *
	 * @param params - The provided params we wish to set in the route
	 */
	protected scrambleParams?(params: RouteFiltersType): RouteFiltersType;
	/**
	 * A method to unscramble the parameters if needed, so no data gets added into the route that shouldn't be shared
	 *
	 * @param params - The provided params we wish to patch in the form
	 */
	protected unscrambleParams?(params: RouteFiltersType): RouteFiltersType;
	/**
	 * A method that can be used to track the filter changes in an analytics tool.
	 * Both the previous and next filters are provided, so they can be compared.
	 *
	 * @param previousFilters - The previous filters
	 * @param nextFilters - The next filters
	 */
	protected handleTracking?(previousFilters: RouteFiltersType, nextFilters: RouteFiltersType): void;
}
