import { Injectable, OnDestroy } from '@angular/core';
import { exhaustMap, finalize, Observable, shareReplay, Subject, takeUntil, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class VlaioSingleCallService implements OnDestroy {
	private readonly destroyedSubject: Subject<void> = new Subject<void>();
	private readonly subjectsRecord: Record<string, Subject<unknown>> = {};
	private readonly callsRecord: Record<string, Observable<unknown>> = {};

	/**
	 * Registers a backend call to the SingleCallService
	 *
	 * @template CallType - The return type of the call
	 * @template OptionsType - An optional type of the options
	 * @param id - The id of the call
	 * @param call - The call we wish to perform
	 */
	public register<CallType, OptionsType = void>(
		id: string,
		call: (options?: OptionsType) => Observable<CallType>
	): void {
		// Iben: If the subject already exists, we throw a warn and early exit
		if (this.subjectsRecord[id]) {
			console.warn('VlaioSingleCallService: The id of this call has already been registered');

			return;
		}

		// Iben: Create a new subject for the call
		this.subjectsRecord[id] = new Subject<OptionsType>();

		//Iben: Subscribe to the subject and handle the call
		this.subjectsRecord[id]
			.pipe(
				exhaustMap((options) => {
					// Iben: Save the call in the record so we can refer to it later on
					this.callsRecord[id] = (options ? call() : call(options as OptionsType)).pipe(shareReplay(1));

					// Iben: Return the calls record so we can ignore more events as long as this one has not finished
					return this.callsRecord[id].pipe(
						finalize(() => {
							// Iben: Remove the call when finalized
							this.callsRecord[id] = undefined;
						})
					);
				}),
				takeUntil(this.destroyedSubject)
			)
			.subscribe();
	}

	/**
	 * Calls an earlier registered backend call and returns its result
	 *
	 * @template CallType - The return type of the call
	 * @template OptionsType - An optional type of the options
	 * @param id - The id of the call
	 * @param options - An optional set of options we wish to pass to the call
	 */
	public call<CallType, OptionsType = void>(id: string, options?: OptionsType): Observable<CallType> {
		// Iben: Fetch the subject
		const subject = this.subjectsRecord[id];

		// Iben: If the subject does not exist, we error and early exit
		if (!subject) {
			return throwError(
				() => new Error('VlaioSingleCallService: No call was registered to this id, no backend call was made.')
			);
		}

		// Iben: Trigger the call
		subject.next(options);

		// Iben: Fetch the call and check if it exists
		const call = this.callsRecord[id];

		if (!call) {
			return throwError(() => new Error('VlaioSingleCallService: No active backend call was found.'));
		}

		// Iben: Return the call result
		// We explicitly type this as Observable<CallType> to prevent too complex typing earlier on
		return call as Observable<CallType>;
	}

	ngOnDestroy() {
		// Iben: Run the destroy flow
		this.destroyedSubject.next();
		this.destroyedSubject.complete();
	}
}
