import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTagsPaths } from '@vlaio/cypress/shared';

import { VlaioIconComponent } from '../../icon/icon.component';

import { ButtonVariants } from './button.enums';

@Component({
	selector: 'vlaio-button',
	templateUrl: './button.component.html',
	styleUrl: './button.component.scss',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass, CypressTagDirective, VlaioIconComponent]
})
export class VlaioButtonComponent {
	/**
	 * Disable the tabbing of the host itself, only the internal button should be highlightable.
	 */
	@HostBinding('attr.tabindex') private readonly tabindex: number = -1;

	/**
	 * Whether or not the component should be 100% width.
	 *
	 * **Not applicable to the `Close` button variant.**
	 */
	@HostBinding('class.full-width') @Input() public fullWidth: boolean = false;

	/**
	 * The button classes enum exposed to the template.
	 */
	public readonly buttonClasses: typeof ButtonVariants = ButtonVariants;

	/**
	 * The tooltip of the button. This should not be the same as the text.
	 *
	 * This is required since WCAG AA-label dictates it.
	 */
	@Input({ required: true }) public title: string = '';

	/**
	 * The styling to apply to the button.
	 *
	 * Default value is `Regular`
	 */
	@Input() public buttonType: keyof typeof ButtonVariants = 'Regular';

	/**
	 * An extra class to apply to the button.
	 */
	@Input() public customButtonClass: string = '';

	/**
	 * The class of the icon we wish to display
	 */
	@Input() public icon: string;

	/**
	 * Whether or not the button should be disabled.
	 *
	 * Default value is `false`
	 */
	@Input() public isDisabled: boolean = false;

	/**
	 * Provide a 'type' for the button.
	 *
	 * This property follows the options and behavior of the spec:
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
	 */
	@Input() public type: 'submit' | 'reset' | 'button' = null;

	/**
	 * Provide a Cypress Tag to the button.
	 */
	@Input() public buttonCypressTag: CypressTagsPaths;

	/**
	 * Allow the button to has its content centered or spread (spaced-between). This input
	 * was added to allow the button layout to change without the need for `::ng-deep`. This
	 * will only have an effect if the `fullWidth` is set to `true`.
	 *
	 * Default value is `centered`.
	 */
	@Input() public layout: 'centered' | 'spread' = 'centered';

	/**
	 * Emits when the button has been pressed.
	 */
	@Output() public handleClick: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * If the button is not disabled, emit the click.
	 */
	public handleButtonClick(): void {
		if (!this.isDisabled) {
			this.handleClick.emit();
		}
	}
}
