import { PermitsResultEntity, PermitsSummaryResultEntity, RenewablePermitsResultEntity } from '../interfaces';

export const PermitsResultMock: PermitsResultEntity = {
	AangemaaktOp: '2019-08-24T14:15:22Z',
	DoorverwijzingUrl: 'www.website.be/doorverwijzing',
	IsPubliek: true,
	Omschrijving:
		'Dit is een beschrijving van de toelating. Dit bewijst dat je effectief mag wat je zegt dat je mag doen, maar natuurlijk enkel zolang deze toelaging geldig is.',
	ProductId: '1',
	Referentie: 'Referentie 001',
	ReferentieNummer: '2',
	Status: {
		DossierNummer: 'E/20210123/546',
		EindDatum: '2026-01-01T00:00:00Z',
		HernieuwingsDatum: '2026-01-02T00:00:00Z',
		StartDatum: '2021-01-01T00:00:00Z',
		WijzigingsDatum: '2019-09-06T14:15:22Z'
	},
	Titel: 'Toekenningsvoorbeeld',
	ToegekendAan: '0481673913',
	isOngelezen: true,
	ToegekendDoor: {
		Naam: 'Andere voorbeeldorganisatie',
		OrganisatieCode: '13'
	}
};

export const PermitsSummaryResultMock: PermitsSummaryResultEntity = {
	mijnSamenvatting: {
		aantalNieuweToelatingenSindsLaasteKeerAangemeld: 1,
		linkNaarTeHernieuwenToelatingen: 'link/naar/te-hernieuwen/toelatingen',
		linkNaarToelatingenGesorteerdOpLaasteWijzigingsdatum: 'link/naar/toelatingen/gesorteerd/op/wijzigingsdatum',
		totaalAantalToelatingen: 2
	},
	mijnTeHernieuwenToelatingen: { elementen: [], '@id': 'test', '@type': '' },
	mijnToelatingen: { elementen: [], '@id': 'test', '@type': '' }
};

const { ProductNaam, ...PermitsResultRest } = PermitsResultMock;

export const RenewablePermitsResultMock: RenewablePermitsResultEntity = {
	...PermitsResultRest,
	Referentie: 'referentie 001',
	AangevraagdDoor: 'aanvrager'
};
