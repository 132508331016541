import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { NgxModalAbstractComponent, NgxModalService } from '@studiohyperdrive/ngx-inform';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { map } from 'rxjs';

import { ExitWizardModalI18nKeysEntity } from '../interfaces';

@Injectable()
export class ExitWizardService {
	constructor(private readonly modalService: NgxModalService) {}

	public handleModal<
		ComponentClass extends NgxModalAbstractComponent<'Proceed' | 'Cancel', ExitWizardModalI18nKeysEntity>
	>(i18nValues: ExitWizardModalI18nKeysEntity, component: ComponentType<ComponentClass>): ObservableBoolean {
		// Wouter: open modal and set keys
		return this.modalService
			.open({
				component: component,
				role: 'alertdialog',
				data: i18nValues,
				labelledById: 'exit-wizard-modal-title',
				describedById: 'exit-wizard-modal-description'
			})
			.pipe(
				map((value) => {
					return value === 'Proceed';
				})
			);
	}
}
