import { EntityStoreAssets, createEntityAdapterStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';

import { NaceBelEntity, NacebelSearchEntity } from './interfaces';

export type NacebelCodeStoreSliceType = {
	nacebelCodes: EntityStoreAssets<NaceBelEntity>;
	nacebelSearchResults: EntityStoreAssets<NacebelSearchEntity>;
};

export const { actions, reducers, selectors } = createStoreAssets<NacebelCodeStoreSliceType>(StoreSlice.NaceBel, [
	{
		subSlice: 'nacebelCodes',
		generator: createEntityAdapterStoreAssets<NaceBelEntity>
	},
	{
		subSlice: 'nacebelSearchResults',
		generator: createEntityAdapterStoreAssets<NacebelSearchEntity>,
		selectId: (item) => item.code
	}
]);
