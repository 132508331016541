import { Injectable, Renderer2 } from '@angular/core';

import { BrowserService } from './browser.service';

@Injectable()
export class ScriptsService {
	constructor(private readonly browserService: BrowserService) {}

	/**
	 * Attaches all scripts to the dom when the dom is available (only in browser)
	 *
	 * @param renderer - Renderer used to attach the scripts to the dom
	 * @memberof ScriptsService
	 */
	public attachScriptsToDom(renderer: Renderer2) {
		// Iben: Loop over all scripts we need to add
		this.browserService.runInBrowser(({ browserDocument }) => {
			this.scripts.forEach((item) => {
				// Iben: Create a new script tag
				const script = renderer.createElement('script');
				script.type = 'text/javascript';
				script.src = item.url;

				// Iben: Log when the script was loaded correctly and log potential errors
				script.onload = () => {
					console.log(`Script ${item.name} was loaded dynamically`);
				};
				script.onerror = (err) => {
					console.error(`Script ${item.name} was not loaded. Check the error below for more info.`);
					console.dir(err);
				};

				// Iben: Attach script to the dom
				renderer.appendChild(browserDocument.head, script);
			});
		});
	}

	/**
	 * Attaches the questionnaire script to the dom and launches it
	 *
	 * @param renderer - The renderer
	 */
	public attachQuestionnaireScriptToDom(renderer: Renderer2) {
		// Iben: Add the script when we're in the browser
		this.browserService.runInBrowser(({ browserDocument }) => {
			// Iben: Create a new script tag
			const script = renderer.createElement('script');
			script.type = 'text/javascript';
			//TODO: Iben: Add the url to the script
			script.src = 'assets/scripts/questionnaire.js';

			// Iben: Log when the script was loaded correctly and log potential errors
			script.onload = () => {
				console.log(`Questionnaire script was loaded dynamically`);
			};
			script.onerror = (err) => {
				console.error(`The questionnaire script was not loaded. Check the error below for more info.`);
				console.dir(err);
			};

			// Iben: Attach script to the dom
			renderer.appendChild(browserDocument.body, script);
		});
	}

	/**
	 * Attaches Google Analytics to the dom when the dom is available
	 *
	 * @param renderer - Renderer used to attach the scripts to the dom
	 * @param onSuccess - A callback to perform when the scripts were loaded successfully
	 * @memberof ScriptsService
	 */
	public attachGTAGScriptsToDom(renderer: Renderer2, onSuccess: () => void) {
		this.browserService.runInBrowser(({ browserDocument }) => {
			// Iben: attach GTAG manager script
			const gtagScript = renderer.createElement('script');
			gtagScript.id = 'GTAG-SCRIPT';
			gtagScript.type = 'text/javascript';
			gtagScript.src = this.gaScripts.gaScript;
			gtagScript.async = true;
			gtagScript.onload = () => {
				console.log('GTAG Manager script was loaded dynamically');

				// Iben: Attach GTAG init script
				const gtagInitScript = renderer.createElement('script');
				gtagInitScript.id = 'GTAG-INIT-SCRIPT';
				gtagInitScript.type = 'text/javascript';
				gtagInitScript.src = this.gaScripts.initScript;
				gtagInitScript.onload = () => {
					console.log('GTAG init script was loaded dynamically');

					// Iben: Action to trigger when GTAG is loaded
					onSuccess();
				};
				gtagInitScript.onerror = (err) => {
					console.error('GTAG init script was not loaded. Check the error below for more info');
					console.dir(err);
				};
				renderer.appendChild(browserDocument.head, gtagInitScript);
			};
			gtagScript.onerror = (err) => {
				console.error('GTAG Manager was not loaded. Check the error below for more info');
				console.dir(err);
			};

			renderer.appendChild(browserDocument.head, gtagScript);
		});
	}

	/**
	 * Returns a list of scripts we wish to attach to the dom
	 */
	private get scripts(): Record<'name' | 'url', string>[] {
		return [
			{
				name: 'Widget Script Burgerprofiel Prod',
				url: 'https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js'
			},
			{
				name: 'Widget Script Burgerprofiel TNI',
				url: 'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js'
			}
			// Wouter: This is temporarily removed, see ELO-810
			// {
			// 	name: 'Hotjar',
			// 	url: 'assets/scripts/hotjar.js'
			// }
		];
	}

	/**
	 * Returns the init and gtag manager scripts to be attached to the dom
	 */
	private get gaScripts(): Record<'initScript' | 'gaScript', string> {
		return {
			gaScript: 'https://www.googletagmanager.com/gtag/js',
			initScript: 'assets/scripts/gtag.js'
		};
	}
}
