<div>
	<div class="c-modal__header">
		<h3 class="c-modal__title" id="video-modal-title">{{ data.translations.title || 'titel' }}</h3>

		<button class="c-modal__close" (focusClick)="close.emit()">
			<vlaio-icon icon="fa-close" />
		</button>
	</div>

	@if (data.hasCookieConsent) {
		<div class="c-modal__body">
			<div class="c-iframe">
				<iframe
					cypressTag="Shared.VideoModal.Video"
					style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0"
					allowfullscreen
					scrolling="no"
					allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
					[src]="data.videoData.video"
				></iframe>
			</div>
		</div>
	} @else {
		<div class="c-modal__body image-body">
			<img class="u-margin-bottom-sm" alt="" [src]="data.videoData.thumbnail" />

			<vlaio-disclaimer id="video-modal-description">
				<div [outerHTML]="data.translations.videoDescription"></div>

				<vlaio-external-link link="/nl/pagina?page=privacyverklaring" [title]="data.translations.readMoreLabel">
					{{ data.translations.readMoreLabel || 'Read more' }}
				</vlaio-external-link>
			</vlaio-disclaimer>
		</div>
	}
	@if (!data.hasCookieConsent) {
		<div class="c-modal__footer">
			<vlaio-button
				class="c-modal__action"
				cypressTag="Shared.VideoModal.AcceptCookies"
				[title]="data.translations.buttonTitle"
				(handleClick)="action.emit('Cookies')"
			>
				{{ data.translations.buttonLabel }}
			</vlaio-button>
		</div>
	}
</div>
