import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	Output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTags } from '@vlaio/cypress/shared';
import { ProductEntity, CallToActionEntity, CallToActionClickedEvent, RecommendationRating } from '@vlaio/shared/types';
import { VlaioDetailContentComponent, VlaioExternalLinkComponent } from '@vlaio/shared/ui/common';

import { ProductActionLinkEventEntity, ProductActionType } from '../../../data';
import { I18nKeys } from '../../../i18n';
import { ProductAnnotationComponent } from '../product-annotation/product-annotation.component';
import { ProductCTAComponent } from '../product-cta/product-cta.component';
import { ProductRatingComponent } from '../product-rating/product-rating.component';
import { ProductTagComponent } from '../product-tag/product-tag.component';

@Component({
	selector: 'vlaio-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrl: './product-detail.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		VlaioDetailContentComponent,
		VlaioExternalLinkComponent,
		CypressTagDirective,
		ProductAnnotationComponent,
		ProductCTAComponent,
		TranslateModule,
		ProductTagComponent,
		ProductRatingComponent
	]
})
export class ProductDetailComponent {
	/**
	 * The translation keys
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	@HostBinding('attr.data-cy') private readonly cypressTag: string = CypressTags.Offers.Product.Block;

	/**
	 * The product to display.
	 */
	@Input() public product: ProductEntity;

	/**
	 * Whether we wish the title to be highlighted
	 */
	@Input() public highlightTitle: boolean = false;

	/**
	 * Whether the CTAs should be shown.
	 */
	@Input() public showCTA: boolean = true;

	/**
	 * How highly this product is recommended.
	 */
	@Input() public recommendationRating: RecommendationRating;

	/**
	 * Emits the recommendation rating when the recommendation is clicked.
	 */
	@Output() public recommendationRated: EventEmitter<RecommendationRating> = new EventEmitter<RecommendationRating>();

	/**
	 * Emits the action and the product whose CTA has been clicked.
	 */
	@Output() public ctaClicked: EventEmitter<CallToActionClickedEvent> = new EventEmitter<CallToActionClickedEvent>();

	/**
	 * Emits the typeof link that was clicked, along with the product.
	 * This should be used for analytics or side effects, not for navigation handling.
	 */
	@Output() public linkClicked: EventEmitter<ProductActionLinkEventEntity> =
		new EventEmitter<ProductActionLinkEventEntity>();

	//Iben: This elementRef is needed in the product-list.component
	constructor(public readonly elementRef: ElementRef) {}

	/**
	 * Generate a click event for a CTA
	 *
	 * @param action - The action we clicked on
	 */
	public handleCTAClicked(action: CallToActionEntity) {
		// Iben: Emit the correct CTA data to the parent
		this.ctaClicked.emit({
			action,
			product: this.product
		});
	}

	/**
	 * Handle the emitting of a link that was clicked in the card.
	 * This should be used for analytics or side effects, not for navigation handling.
	 *
	 * @param type - The type of link that was clicked
	 */
	public handleLinkClicked(type: keyof typeof ProductActionType) {
		this.linkClicked.emit({
			type,
			product: this.product
		});
	}
}
