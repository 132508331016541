import { inject, Injectable } from '@angular/core';
import { NgxAccessibleDragAndDropAbstractService } from '@studiohyperdrive/ngx-layout';
import { Observable } from 'rxjs';

import { AbstractLanguageProviderService } from '../abstracts';

@Injectable({
	providedIn: 'root'
})
export class VlaioDragAndDropService extends NgxAccessibleDragAndDropAbstractService {
	private i18nService: AbstractLanguageProviderService = inject(AbstractLanguageProviderService);

	get currentLanguage(): string | Observable<string> {
		return this.i18nService.currentLanguage;
	}
}
