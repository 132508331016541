<h4 cypressTag="Shared.PartnerDetail.Name">
	{{ partner }}

	@for (item of contactItems?.serviceNames || []; track item) {
		- {{ item }}
	}
</h4>

@for (item of contactItems?.personNames || []; track item) {
	<p>
		{{ item }}
	</p>
}

@for (item of contactItems?.addresses || []; track item) {
	<p>
		<vlaio-address format="one-line" cypressTag="Shared.PartnerDetail.Address" [address]="item" />
	</p>
}

@if (contactItems?.phoneNumbers || contactItems?.emailAddresses) {
	<p>
		@for (item of contactItems?.phoneNumbers || []; track item) {
			<vlaio-contact-link
				class="c-phone-number"
				contactType="tel"
				cypressTag="Shared.PartnerDetail.Phone"
				[link]="item"
			/>
		}

		@for (item of contactItems?.emailAddresses || []; track item) {
			<vlaio-contact-link
				class="c-email"
				contactType="mail"
				cypressTag="Shared.PartnerDetail.Mail"
				[mailData]="mailData"
				[link]="item"
			/>
		}
	</p>
}

@if (contactItems?.websites) {
	<p>
		@for (item of contactItems.websites; track item) {
			<vlaio-external-link cypressTag="Shared.PartnerDetail.Website" [link]="item.url" [title]="item.url">
				{{ item.text || item.url }}
			</vlaio-external-link>
		}
	</p>
}
