import { marker } from '@ngneat/transloco-keys-manager/marker';

export const sharedI18nKeys = {
	Loading: marker('loading'),
	Offline: marker('offline'),
	BusyRetry: marker('busy-retry'),
	Actions: {
		MoreInfo: marker('actions.more-info'),
		MoreInformation: marker('actions.more-information'),
		ProductSearch: {
			Text: marker('actions.product-search.text'),
			Title: marker('actions.product-search.title')
		},
		LetsStart: marker('actions.lets-start'),
		More: marker('actions.more'),
		TryAgain: marker('actions.try-again'),
		Search: marker('actions.search'),
		ClearAll: marker('actions.clear-all'),
		LoadMore: marker('actions.load-more'),
		Request: {
			Text: marker('actions.request.text'),
			Title: marker('actions.request.title')
		},
		Sort: marker('actions.sort'),
		Delete: {
			Text: marker('actions.delete.text'),
			Title: marker('actions.delete.title')
		},
		Edit: marker('actions.edit'),
		DeleteAll: {
			Text: marker('actions.delete-all.text'),
			Title: marker('actions.delete-all.title')
		},
		Save: {
			Text: marker('actions.save.text'),
			Title: marker('actions.save.title')
		},
		Cancel: {
			Text: marker('actions.cancel.text'),
			Title: marker('actions.cancel.title')
		},
		Continue: {
			Text: marker('actions.continue.text')
		},
		Filter: marker('actions.filter'),
		ShowMore: marker('actions.show-more'),
		ShowLess: marker('actions.show-less'),
		StartTour: marker('actions.start-tour'),
		HelpTour: marker('actions.help-tour')
	},
	Tour: {
		Start: {
			Text: marker('tour.start.text'),
			Title: marker('tour.start.title')
		},
		Next: {
			Text: marker('tour.next.text'),
			Title: marker('tour.next.title')
		},
		Previous: {
			Text: marker('tour.previous.text'),
			Title: marker('tour.previous.title')
		},
		Close: {
			Text: marker('tour.close.text'),
			Title: marker('tour.close.title')
		}
	},
	Common: {
		Next: marker('common.next'),
		Previous: marker('common.previous'),
		Close: marker('common.close'),
		Empty: {
			Address: marker('common.empty.address'),
			NoData: marker('common.empty.no-data')
		},
		Via: marker('common.via'),
		Login: marker('common.login'),
		Back: marker('common.back'),
		Address: {
			AdditionPrefix: 'common.address.additionPrefix'
		},
		Menu: marker('common.menu'),
		Prepositions: {
			In: marker('common.prepositions.in')
		},
		Yes: marker('common.yes'),
		No: marker('common.no'),
		Or: marker('common.or'),
		OrganizationNumber: marker('common.organization-number'),
		OrganizationNumberToolTip: marker('common.organization-number-tooltip'),
		DesktopOnly: marker('common.desktop-only'),
		Error: marker('common.error'),
		New: marker('common.new'),
		YourInfo: marker('common.your-info'),
		File: marker('common.file')
	},
	FormErrors: {
		InvalidInsz: marker('form-errors.invalid-insz'),
		Required: marker('form-errors.required'),
		MinLength: marker('form-errors.min-length'),
		MinDate: marker('form-errors.min-date'),
		ChronologicalDates: marker('form-errors.chronological-dates'),
		MandateExecutorRuntime: marker('form-errors.mandate-executor-runtime')
	},
	SaveOnExit: {
		Modal: {
			Title: marker('save-on-exit.modal.title'),
			Description: marker('save-on-exit.modal.description'),
			DescriptionInvalid: marker('save-on-exit.modal.description-invalid'),
			Save: marker('save-on-exit.modal.save'),
			Discard: marker('save-on-exit.modal.discard'),
			Cancel: marker('save-on-exit.modal.cancel')
		}
	},
	PageTitles: {
		Home: marker('page-titles.home'),
		Offers: marker('page-titles.offers'),
		AboutUs: marker('page-titles.about-us'),
		Partners: marker('page-titles.partners'),
		Faq: marker('page-titles.faq'),
		MyELoket: marker('page-titles.my-e-loket'),
		RightsManagement: marker('page-titles.rights-management'),
		UserStatus: marker('page-titles.user-status'),
		ELoket: {
			Dashboard: marker('page-titles.e-loket.dashboard'),
			Cases: marker('page-titles.e-loket.cases'),
			Products: marker('page-titles.e-loket.products'),
			Recommendations: marker('page-titles.e-loket.recommendations'),
			Company: marker('page-titles.e-loket.company'),
			Search: marker('page-titles.e-loket.search'),
			EBox: marker('page-titles.e-loket.e-box'),
			Feedback: marker('page-titles.e-loket.feedback'),
			Mandates: marker('page-titles.e-loket.mandates'),
			MandatesGiver: marker('page-titles.e-loket.mandates-giver'),
			MandatesTaker: marker('page-titles.e-loket.mandates-taker'),
			MandatesExecutors: marker('page-titles.e-loket.mandates-executors'),
			Sanctions: marker('page-titles.e-loket.sanctions'),
			Permits: marker('page-titles.e-loket.permits'),
			Inbox: marker('page-titles.e-loket.inbox'),
			Subsidies: marker('page-titles.e-loket.subsidies'),
			Redirect: marker('page-titles.e-loket.redirect'),
			Pwa: marker('page-titles.e-loket.pwa')
		},
		Mandates: {
			SelfRegistration: {
				Application: marker('page-titles.mandates.self-registration.application'),
				Overview: marker('page-titles.mandates.self-registration.overview')
			}
		}
	},
	PageDescriptions: {
		Home: marker('page-descriptions.home'),
		Offers: marker('page-descriptions.offers'),
		AboutUs: marker('page-descriptions.about-us'),
		Partners: marker('page-descriptions.partners'),
		Faq: marker('page-descriptions.faq'),
		MyELoket: marker('page-descriptions.my-e-loket'),
		RightsManagement: marker('page-descriptions.rights-management'),
		UserStatus: marker('page-descriptions.user-status'),
		ELoket: {
			Dashboard: marker('page-descriptions.e-loket.dashboard'),
			Cases: marker('page-descriptions.e-loket.cases'),
			Products: marker('page-descriptions.e-loket.products'),
			Recommendations: marker('page-descriptions.e-loket.recommendations'),
			Company: marker('page-descriptions.e-loket.company'),
			Search: marker('page-descriptions.e-loket.search'),
			Inbox: marker('page-descriptions.e-loket.inbox'),
			EBox: marker('page-descriptions.e-loket.e-box'),
			Subsidies: marker('page-descriptions.e-loket.subsidies'),
			Feedback: marker('page-descriptions.e-loket.feedback'),
			Mandates: marker('page-descriptions.e-loket.mandates'),
			MandatesGiver: marker('page-descriptions.e-loket.mandates-giver'),
			MandatesTaker: marker('page-descriptions.e-loket.mandates-taker'),
			MandatesExecutors: marker('page-descriptions.e-loket.mandates-executors'),
			Permits: marker('page-descriptions.e-loket.permits'),
			Pwa: marker('page-descriptions.e-loket.pwa'),
			Redirect: marker('page-descriptions.e-loket.redirect'),
			SelfRegistration: {
				Overview: marker('page-descriptions.e-loket.self-registration.overview')
			}
		}
	},
	Cookies: {
		ButtonLabel: marker('cookies.button-label'),
		ButtonTitle: marker('cookies.button-title'),
		Video: marker('cookies.video'),
		ReadMore: marker('cookies.read-more')
	},
	Browser: {
		Title: marker('browser.title'),
		Actions: {
			Update: marker('browser.actions.update'),
			Ignore: marker('browser.actions.ignore')
		}
	},
	Menu: {
		Logout: marker('menu.logout')
	}
};
