import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDisplayContentComponent } from '@studiohyperdrive/ngx-layout';

import { I18nKeys } from '../../../i18n';
import { VlaioDisclaimerComponent } from '../../disclaimer';

@Component({
	selector: 'vlaio-offline',
	standalone: true,
	template: ` <vlaio-disclaimer type="danger">
		<p>{{ i18nKeys.Offline | translate }}</p>
	</vlaio-disclaimer>`,
	imports: [VlaioDisclaimerComponent, TranslateModule],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlaioOfflineComponent extends NgxDisplayContentComponent {
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
}
