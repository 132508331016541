import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgxCookieService } from '@studiohyperdrive/ngx-cookies';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { NgxDisplayContentDirective } from '@studiohyperdrive/ngx-layout';
import { replaceHtmlWhitespace } from '@studiohyperdrive/utils';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { BrowserService } from '@vlaio/shared/core';
import { sharedI18nKeys } from '@vlaio/shared/i18n';
import { PageEntity } from '@vlaio/shared/types';

@Component({
	selector: 'vlaio-page',
	templateUrl: './page.component.html',
	styleUrl: './page.component.scss',
	imports: [NgxDisplayContentDirective],
	standalone: true
})
export class PageComponent {
	private readonly videoElementTag: string = 'c-iframe';
	private readonly initializedSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private originalText: string;

	public title: string;
	public content: SafeHtml;

	@Input() set page(page: PageEntity) {
		// Iben: Scroll to the top of the body whenever we navigate to a new page
		this.browserService.runInBrowser(({ browserDocument }) => {
			// Iben: The use of the body and the setTimeOut gave the most consistent results
			// TODO: remove once Angular fixed their scrollPositionRestoration issues
			setTimeout(() => {
				browserDocument.body.scrollTo({ top: 0 });
			});
		});

		this.initializedSubject$.next(false);

		// Iben: If there is no page, early exit
		if (!page) {
			return;
		}

		// Iben: Set the data and mark the html as trusted
		this.title = page.title;
		this.content = this.sanitizer.bypassSecurityTrustHtml(replaceHtmlWhitespace(page.text));
		this.originalText = page.text;

		// Iben: Skip one detection cycle and handle the content (TODO: If Angular ever decides to make detectChanges an observable, use that implementation)
		setTimeout(() => {
			// Iben: Handle the cookie related content
			this.handleContent();

			// Iben: Add anchors to each title so we can navigate to these later
			this.handleAnchors();

			// Iben: Mark the initialization as complete
			this.initializedSubject$.next(true);
		});
	}

	@Input() set scrollToAnchor(anchor: string) {
		// Iben: If no anchor was provided, we early exit
		if (!anchor) {
			return;
		}

		this.browserService.runInBrowser(({ browserDocument, browserWindow }) => {
			// Iben: Hold a scrolled subject so we can destroy the subscription later on
			const scrolled = new Subject<void>();

			// Iben: Wait until the content has been initialized
			this.initializedSubject$
				.pipe(
					filter(Boolean),
					tap(() => {
						// Iben: Fetch the element we want to scroll to
						const element = browserDocument.getElementById(anchor);

						// Iben: Scroll into view and scroll up by 100 px so the headers aren't overlapping on top of the item
						if (element) {
							element.scrollIntoView();
							browserWindow.scrollBy({ top: -100 });
						}

						// Iben: Complete the subscription
						scrolled.next();
						scrolled.complete();
					}),
					takeUntil(scrolled)
				)
				.subscribe();
		});
	}
	@Input() showTitle: boolean = true;

	/**
	 * Whether the page content is being fetched.
	 */
	@Input() public loading: boolean = false;

	/**
	 * Whether an error occurred while fetching the page content.
	 */
	@Input() public error: boolean = false;

	@ViewChild('pageContent') pageContent: ElementRef;

	constructor(
		private readonly sanitizer: DomSanitizer,
		private readonly cdRef: ChangeDetectorRef,
		private readonly renderer: Renderer2,
		private readonly i18nService: NgxI18nService,
		private readonly browserService: BrowserService,
		private readonly cookieService: NgxCookieService
	) {}

	private handleContent() {
		this.cookieService
			.hasAcceptedService('marketing', 'youtube')
			.pipe(
				switchMap((hasAcceptedYouTube) => {
					if (hasAcceptedYouTube) {
						return of();
					}

					// Iben: Fetch all elements with a video tag
					const elements: HTMLCollection =
						this.pageContent.nativeElement.getElementsByClassName(this.videoElementTag) || {};

					// Iben: Replace all the video elements with a cookies tag
					return combineLatest(
						Object.values(elements).map((element) => {
							// Iben: In case we have a YouTube video, we fetch the id so we can show a thumbnail
							const videoSrc: string = (element?.children[0] as any)?.src || '';
							let videoId;

							if (videoSrc.includes('youtube')) {
								videoId = new URL(videoSrc).searchParams.get('v');

								// Iben: In case the id wasn't passed with the parameter, we use the last item of the video src
								if (!videoId) {
									videoId = videoSrc.split('/').pop();
								}
							}

							// Iben: Create a cookie element to replace the current element with
							return this.getVideoCookieElement(videoId).pipe(
								tap((videoElement) => {
									// Iben: Create a new element to attach to the DOM;
									const replacementElement = this.renderer.createElement('div');

									// Iben: Add the data to the element and style it accordingly
									this.renderer.setProperty(replacementElement, 'innerHTML', videoElement);
									this.renderer.setAttribute(replacementElement, 'class', 'c-cookie__video');

									// Iben: Add an eventListener to the button so we can accept the cookie
									replacementElement
										.getElementsByClassName('c-accept-cookie-button')[0]
										.addEventListener('click', () => {
											this.cookieService.acceptService('marketing', 'youtube');
											// Iben: Detect changes so that we go through this function again
											this.content = this.sanitizer.bypassSecurityTrustHtml(this.originalText);
											this.cdRef.detectChanges();
										});

									// Iben: Replace the element with the new cookie element
									element.parentNode.replaceChild(replacementElement, element);
								})
							);
						})
					);
				})
			)
			.subscribe();
	}

	/**
	 * Generates a video cookie element to inform users to accept the cookies before they watch the video
	 *
	 * @private
	 * @param videoId: Optional YouTube video id
	 */
	private getVideoCookieElement(videoId?: string) {
		return combineLatest([
			this.i18nService.getTranslationObservable(sharedI18nKeys.Cookies.Video),
			this.i18nService.getTranslationObservable(sharedI18nKeys.Cookies.ButtonLabel),
			this.i18nService.getTranslationObservable(sharedI18nKeys.Cookies.ReadMore)
		]).pipe(
			filter<[string, string, string]>(
				([video, agree, readMore]) => Boolean(video) && Boolean(agree) && Boolean(readMore)
			),
			take(1),
			map(([video, agree, readMore]) => {
				return `
				${videoId ? `<img src="http://img.youtube.com/vi/${videoId}/maxresdefault.jpg">` : ''}
				<p>
					${video}
					<a title="${readMore}" role="button" tabindex="0" class="u-translation-link external-link" href="/nl/pagina?page=privacyverklaring" rel="noopener noreferrer nofollow" target="_blank">
					${readMore}
					</a>
				</p>

				<button class="vlaio-button c-accept-cookie-button">
					${agree}
				</button>
			`;
			})
		);
	}

	/**
	 * Adds ids to all title tags so that we can automatically scroll to a specific title
	 */
	private handleAnchors() {
		// Iben: Fetch all h elements
		const elements: HTMLCollection = {
			...(this.pageContent.nativeElement.getElementsByTagName('h1') || {}),
			...(this.pageContent.nativeElement.getElementsByTagName('h2') || {}),
			...(this.pageContent.nativeElement.getElementsByTagName('h3') || {}),
			...(this.pageContent.nativeElement.getElementsByTagName('h4') || {}),
			...(this.pageContent.nativeElement.getElementsByTagName('h5') || {}),
			...(this.pageContent.nativeElement.getElementsByTagName('h6') || {})
		};

		// Iben: Add an anchor based on the provided text
		Object.values(elements).forEach(
			(element) =>
				(element.id = `anchor_${element.innerHTML.toLowerCase().replaceAll(' ', '_').replaceAll('?', '')}`)
		);
	}
}
